@import url("https://fonts.googleapis.com/css?family=Roboto+Mono|Roboto+Slab|Roboto:300,400,500,700");

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-size: 0.9rem;
  overflow-x: hidden;
  font-style: normal;
  color: #393939;
  background-color: #fff;
  font-family: "Avenir", Arial, Helvetica, sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
div,
header,
section {
  margin: 0;
}

h1{
  font-weight: 900 !important;
}

button,
input {
  border: none;
  outline: none;
}

.approvePending{
border-radius: 20px;
color:white;
background-color: #1c811c;
margin: 0 5px;
padding:5px 15px;
cursor: pointer;
position: relative;
}
.approvePending:hover{
  border:2px solid #1890FF

}
.deletez:hover{
  background-color: teal;
  color:white
}
.deletezz:hover{
  background-color: rgb(19, 131, 9);
  color:white
}
.badger{
background-color: red;
color: white;
border-radius: 50%;
width: 25px;
padding:2.5px;
text-align: center;
position: absolute;
right: 0;
top: -15px;
}

.approvePending1{
  border-radius: 20px;
  color:white;
  background-color: orange;
  margin: 0 5px;
  padding:5px 15px;
  }

section {
  width: 100%;
  margin: 0;
}
.self-align{
  align-self: center;
}

.p-r {
  position: relative !important;
}

.no-padding {
  padding: 0 !important;
}
.p-10 {
  padding: 10px !important;
}
.p-20 {
  padding: 20px !important;
}
.p-40 {
  padding: 40px !important;
}
.p-t-40 {
  padding-top: 40px !important;
}
.p-r-5 {
  padding-right: 5px !important;
}
.p-l-5 {
  padding-left: 5px !important;
}
.p-l-20 {
  padding-left: 20px !important;
}

.no-margin {
  margin: 0 !important;
}
.m-l-5 {
  margin-left: 5px !important;
}
.m-r-5 {
  margin-right: 5px !important;
}
.m-l-10 {
  margin-left: 10px !important;
}
.m-l-20 {
  margin-left: 20px !important;
}
.m-l-25 {
  margin-left: 27% !important;
}
.m-r-5 {
  margin-right: 5px !important;
}
.m-t-10 {
  margin-top: 10px !important;
}
.m-t-20 {
  margin-top: 20px !important;
}
.m-t--20 {
  margin-top: -20px !important;
}
.m-t-40 {
  margin-top: 40px !important;
}
.m-t-5 {
  margin-top: 5px !important;
}
.m-b-5 {
  margin-bottom: 5px !important;
}
.m-b-10 {
  margin-bottom: 10px !important;
}
.m-b-20 {
  margin-bottom: 20px !important;
}
.m-b-40 {
  margin-bottom: 40px !important;
}
.m-b-50 {
  margin-bottom: 50px !important;
}

.no-border {
  border: none !important;
}
.no-wrap {
  flex-wrap: nowrap !important;
}
.wrap {
  flex-wrap: wrap !important;
}
.flex {
  display: flex !important;
}
.flex-h-center {
  justify-content: center !important;
}
.flex-v-center {
  align-items: center !important;
}
.pointer {
  cursor: pointer !important;
}
.underline {
  text-decoration: underline !important;
}
.center-text {
  text-align: center !important;
}
.right-text {
  text-align: right !important;
}
.space-between {
  justify-content: space-between !important;
}
.float-right {
  float: right !important;
}
.inline-block {
  display: inline-block !important;
}
.bold-text {
  font-weight: bold !important;
}

.max-w-400 {
  max-width: 400px !important;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-half,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  display: inline-block;
  position: relative;
  padding-right: 10px;
  padding-left: 10px;
  margin-bottom: 20px;
}
.col-1 {
  width: 8.33%;
}
.col-2 {
  width: 16.66%;
}
.col-3 {
  width: 25%;
}
.col-4 {
  width: 33.33%;
}
.col-5 {
  width: 41.65%;
}
.col-6,
.col-half {
  width: 50%;
}
.col-7 {
  width: 58.33%;
}
.col-8 {
  width: 66.66%;
}
.col-9 {
  width: 75%;
}
.col-10 {
  width: 83.33%;
}
.col-11 {
  width: 91.63%;
}
.col-12,
.w-100 {
  width: 100% !important;
  position: relative;
}

.w-50 {
  width: 50% !important;
  position: relative;
}

.w-10 {
  width: 25%;
  padding-left: 100px;
}
.bt1 {
  text-align: center;
  display: flex;
  justify-content: center;
}

.bt2 {
  flex: 1;
}
.w1 {
  width: 5%;
}
.row {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 20px);
  position: relative;
  margin-right: -10px;
  margin-left: -10px;
}

.content {
  /* max-width: 1300px; */
  margin: 0 auto;
  padding: 20px;
  flex-wrap: wrap;
  display: flex;
}
.flex-direction-column {
  flex-direction: column !important;
}
footer {
  position: relative;
  background-color: #454545;
  color: #ddd;
  height: 58px;
}

div.banner {
  width: 100%;
  padding: 130px 0 100px;
  background-size: cover;
  background-blend-mode: luminosity;
  background-image: url("../images/banner.png");
  background-color: #1c811c55;
  background-position-y: 20%;
}

div.banner .login {
  margin: 0 auto;
  max-width: 600px;
  padding: 40px;
  position: unset;
  border-radius: 10px;
  background-color: #fffffff0;
}
div.login {
  display: flex;
}
div.login .coat-of-arm {
  border-right: 1px solid #c6c6c6;
  padding-top: 15px;
  padding-right: 40px;
  width: 270px;
}
div.login .coat-of-arm img {
  width: 140px;
  height: auto;
}
div.login .coat-of-arm h2 {
  font-size: 1.58rem;
  font-family: sans-serif;
  line-height: 1.5rem;
  font-weight: 700;
}
div.login .coat-of-arm h4 {
  font-size: 1.25rem;
  font-weight: 400;
}
div.login .form {
  padding-left: 40px;
  width: 100%;
  text-align: center;
}
.login-form-group {
  width: 100%;
  margin-bottom: 20px;
}
.login-form-group {
  width: 100%;
  margin-bottom: 15px;
}
.login-form-group input {
  width: 100%;
  padding: 13px;
  border: 1px solid #c6c6c6;
  border-radius: 5px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.9rem;
  color: #777;
}
div.login .form button {
  width: 100%;
  color: #fff;
  padding: 13px;
  background-color: #017d34;
  border-radius: 5px;
  outline: none;
  border: none;
  transition: all 0.3s ease-out;
}
div.login .form button:hover {
  background-color: #017d3488;
}
.intro {
  max-width: 800px;
  margin: 30px auto 10px;
}
div.dept-news {
  background-color: #ffffff;
  box-shadow: 5px 3px 22px #958e8e29;
  border: 1px solid #c6c6c6;
  border-radius: 10px;
  padding: 20px;
  position: relative;
}
div.dept-news h4 {
  background-color: #1c811c;
  color: #ffffff;
  padding: 5px 10px 2px;
  display: inline-block;
  position: relative;
  right: 20px;
}
.headline {
  align-items: center;
  border-bottom: 1px solid #c6c6c6;
  padding-bottom: 20px;
}
.headline:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.headline .date {
  background-color: #1c811c1f;
  padding: 5px 10px;
  text-align: center;
}
.headline .date .m {
  font-size: 0.7rem;
}
.headline .date .d {
  font-size: 1.4rem;
}
.headline .title {
  margin-left: 10px;
}
div.bottom {
  border-left: 1px solid #c6c6c6;
  padding: 0 20px;
}
div.bottom h2 {
  color: #1c811c;
  font-family: sans-serif;
  margin-bottom: 20px;
}
div.bottom ol {
  padding-left: 20px;
}
div.bottom ul {
  padding: 0;
  list-style: none;
}

@media only screen and (max-width: 900px) {
  .col-8,
  .col-7,
  .col-6 {
    width: 100%;
    flex: 1 0 100%;
  }
  .col-5,
  .col-4,
  .col-3 {
    width: 50%;
    flex: 1 0 50%;
  }
}

@media only screen and (max-width: 600px) {
  .col-5,
  .col-4,
  .col-3 {
    width: 100%;
    flex: 1 0 100%;
  }
  div.banner {
    padding-top: 60px;
  }
  div.login {
    display: block;
    margin: 20px !important;
  }
  div.login .coat-of-arm {
    border-right: none;
    padding-bottom: 20px;
    padding-right: 0;
    text-align: center;
    width: 100%;
  }
  div.login .form {
    padding-left: 0;
  }
}

.card {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 1px;
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  transition: all 0.2s ease;
  position: relative;
  margin-bottom: 2px;
  width: 100%;
  word-wrap: none;
  
}
.card:hover{
  background-color: #27f12766;
}
.card .card-header {
  background: transparent;
  border-radius: 0px;
  border-bottom: 0px;
  padding: 20px 20px 7px 20px;
  position: relative;
  z-index: 3;
  min-height: 48px;
}
.card .card-header.separator:after {
  content: "";
  height: 1px;
  background: rgba(0, 0, 0, 0.08);
  left: 16px;
  right: 16px;
  position: absolute;
  bottom: 0;
}
.card .card-header + .card-body {
  padding-top: 0;
}
.card .card-header .card-title {
  font-family: "Montserrat";
  text-transform: uppercase;
  display: inline-block;
  letter-spacing: 0.06em;
  font-size: 10.5px;
  font-weight: 500;
  margin: 0;
  padding: 0;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-text-stroke: 0px;
  filter: alpha(opacity=40);

  transition: opacity 0.3s ease;

  -webkit-filter: alpha(opacity=40);
}
.card .card-header .card-controls {
  float: left;
  margin-top: -3px;
}
.card .card-header .card-controls ul {
  margin: 0;
  padding: 0;
}
.card .card-header .card-controls ul li {
  display: inline-block;
  list-style: none;
  line-height: 0;
}
.card .card-header .card-controls:after {
  content: "";
  display: table;
  clear: both;
}
.card .card-header.card-header-hover .card-title {
  opacity: 0.4;
}
.card .card-header.card-header-hover:hover .card-title {
  opacity: 1;
}
.card.card-default {
  border: 1px solid rgba(0, 0, 0, 0.07);
}
.card.card-default > .card-header {
  color: #333;
}
.card.card-bordered {
  border: 1px solid rgba(230, 230, 230, 0.7);
}
.card.card-borderless {
  border: none;
}

.search-border{
  border: 1px solid black;
  padding-left: 10px;
  color: black;
}
.card.card-condensed .card-header {
  padding: 13px 13px 0 13px;
  min-height: 30px;
}
.card.card-condensed .card-header .card-title {
  opacity: 0.4;
}
.card.card-condensed .card-body {
  padding: 13px;
}
.card.card-hover .card-header .card-title {
  opacity: 0.4;
}
.card.card-hover:hover .card-header .card-title {
  opacity: 1;
}
.card.card-transparent {
  background: transparent;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.card.card-transparent .card-body {
  background: transparent;
}
.card.full-height {
  height: calc(100%);
}
.card.full-height .card-body {
  height: auto;
  width: 100%;
  height: 100%;
}
.card.card-featured {
  -webkit-box-shadow: -1px 1px 3px 0px rgba(121, 129, 135, 0.14);
  box-shadow: -1px 1px 3px 0px rgba(121, 129, 135, 0.14);
  width: calc(100% - 50px);
  float: left;
}
.card.card-featured .card-title h4 {
  font-family: "Montserrat";
  font-size: 16px;
  text-transform: uppercase;
  color: #f0f0f0;
}
.card.card-featured .card-body h3 {
  line-height: 34px;
  font-size: 26px;
}

.color-red{
  color: red;
}

.size-large{
  font-size: large;
}

.card.hover-fill:hover {
  background: #f0f0f0;
}
.card.hover-stroke:hover {
  border: 1px solid #e6e6e6;
}
.card .card-body {
  padding: 20px;
}
.card .card-body.no-padding .row {
  margin-left: 0;
  margin-right: 0;
}
.card .card-body.no-bottom-padding {
  padding-bottom: 0;
}
.card .card-body.no-top-padding {
  padding-top: 0;
}
.card .card-body .title {
  margin-top: 0px;
}
.card .card-body.scrollable {
  margin-bottom: 20px;
}

input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  border-radius: 77px;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.file-preview {
  margin: 0 10px;
}

.dot {
  height: 15px;
  width: 15px;
  background-color: #fdcc29;
  border-radius: 50%;
  display: inline-block;

  margin-top: auto;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.vl {
  border-left: 3px solid rgb(180, 176, 176);
  height: 50px;
  left: 50%;
  margin-left: 300px;
  margin-top: 18px;
}

.loading-overlay {
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #1c811c66;
  position: fixed;
  z-index: 1000;
  padding: 100px 20px;
  overflow: auto;
}

.loading-inner-box {
  padding: 50px 20px;
  width: 320px;
  text-align: center;
  box-shadow: 0 0 10px #888;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}
.float-right{

}
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.border-color-red{
  border: 1px solid red;
}
.form-group > label,
.checkbox > label {
  position: relative;
}
.form-group.required > label:before,
.checkbox.required > label:before {
  content: "\f069";
  position: absolute;
  top: -2px;
  right: -10px;
  color: #c00;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 200;
  text-decoration: inherit;
  font-size: 8px;
}

.statsicon {
  top: 315px;
  left: 568px;
  width: 119px;
  height: 43px;
  opacity: 1;
}

.eventnote {
  top: 315px;
  left: 568px;
  width: 119px;
  height: 43px;
  opacity: 1;
}

.notificon {
  top: 315px;
  left: 568px;
  width: 119px;
  height: 43px;
  opacity: 1;
}

.card-wrapper {
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
}

.table-pagination-btn {
  background-color: #4caf50 /* Green */;
  border: none;
  color: white;
  padding: 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 50%;
}


/* styles.css */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  margin: 20px 20px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}